<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    title="分配车辆"
  >
    <div class="top">
      <div class="top-tree">
        <div class="top-tree-scroll">
          <el-tree
            ref="tree"
            :data="treeData"
            :props="defaultProps"
            node-key="id"
            highlight-current
            @node-click="treeNodeClick"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpanded"
            :filter-node-method="filterNode"
          ></el-tree>
        </div>
      </div>
      <div class="top-carList">
        <div class="top-carList-scroll" ref="top-carList-scroll">
          <!-- <el-checkbox-group
            v-model="checkAllObdList"
            @change="handleCheckAllObdList"
          >
            <el-row v-for="(item, index) in allObdList" :keys="index">
              <el-checkbox :label="item.carPlateNum">
                {{ item.carPlateNum }} -
                {{ item.carPlateColor }}
              </el-checkbox>
            </el-row>
          </el-checkbox-group> -->
          <!-- <el-table
            :data="allObdList"
            style="width: 100%"
            @selection-change="handleCheckAllObdList"
            :show-header="false"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column>
              <template slot-scope="scope">{{ scope.row.carPlateNum }}</template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">{{ scope.row.carPlateColor }}</template>
            </el-table-column>
          </el-table> -->
          <div style="height: 40px; display: flex; align-items: center;">
            <el-input
              v-model="filterText"
              size="small"
              placeholder="请输入关键字进行过滤"
              :suffix-icon="suffixIcon"
            />
          </div>
          <Table
            context-menu
            border
            stripe
            :height="tableHeight"
            :columns="columns"
            :data="tableData"
            @on-selection-change="selectChange"
            @on-select-cancel="selectCancelRow"
            @on-select-all-cancel="selectAllCancel"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-scroll">
        <el-tag
          v-for="(item, index) in checkAllObdList"
          :key="index"
          type="info"
          closable
          @close="handleClose(item, index)"
          style="margin-right: 4px"
        >
          {{ item.carPlateNum }}-{{ item.carPlateColor }}
        </el-tag>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      visible: false,
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeData: [],
      curNode: {},
      rowData: null,
      allObdList: [],
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "全选",
          key: "carPlateNum",
          align: "center",
          ellipsis: true,
        },
        {
          title: " ",
          key: "carPlateColor",
          align: "center",
          ellipsis: true,
        },
      ],
      checkAllObdList: [], //所有选中的数据
      curChecked: [], //当前选中的数据
      tableHeight: null,
      filterText: "",
      tableData: [],
      timer: null,
      suffixIcon: "",
    };
  },
  computed: {
    defaultExpanded() {
      let _B = Boolean(this.treeData && this.treeData.length);
      let arr = _B ? [this.treeData[0].id] : [];
      return arr;
    },
  },
  watch: {
    curChecked: {
      handler(val) {
        val.forEach((i) => {
          let _i = this.checkAllObdList.find(
            (e) =>
              e.carPlateNum == i.carPlateNum &&
              e.carPlateColor == i.carPlateColor
          );
          if (_i == undefined) {
            this.checkAllObdList.push(i);
          }
        });
      },
      immediate: true,
    },
    filterText(val) {
      !this.suffixIcon ? (this.suffixIcon = "el-icon-loading") : "";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (val != "") {
          this.tableData = this.allObdList.filter(
            (el) => el.carPlateNum.indexOf(val) != -1
          );
        } else {
          this.tableData = [...this.allObdList];
        }
        this.suffixIcon = "";
      }, 1000);
    },
  },
  methods: {
    show(row) {
      this.treeData = [];
      this.curNode = {};
      this.allObdList = [];
      this.checkAllObdList = [];
      this.curChecked = [];
      this.visible = true;
      this.rowData = row;
      this.getTreeData(row.userId);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getTreeData(userId) {
      this.$http({
        url: this.$http.adornUrl("sys/orgArch/userList"),
        method: "get",
        params: this.$http.adornParams({
          userId,
        }),
      })
        // this.$http({
        //   url: this.$http.adornUrl("sys/orgArch/list"),
        //   method: "get",
        // })
        .then((res) => {
          this.treeData = treeDataTranslate(res.data.data, "id", "parendId");
          this.curNode =
            this.treeData && this.treeData.length ? this.treeData[0] : {};
          this.$refs.tree.setCurrentKey(this.curNode.id);
          this.getAllObdList();
          this.getEmuserEquipment();
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      //点击树节点
      this.curNode = JSON.parse(JSON.stringify(node));
      this.getAllObdList();
    },
    getAllObdList() {
      this.curChecked = [];

      // this.$http({
      //   url: this.$http.adornUrl("obd/selectEmuserEquipment"),
      //   method: "get",
      //   params: this.$http.adornParams({
      //     userId: this.rowData.userId,
      //   }),
      // })
      //   .then((res) => {
      //     this.checkAllObdList = res.data.data;
      //     if (!this.isAuth('obd:getEquipmentInfo')) return this.$message.warning("没有权限!obd:getEquipmentInfo");
      //     return this.$http({
      //       url: this.$http.adornUrl("obd/getEquipmentInfo"),
      //       method: "post",
      //       data: {
      //         userId: this.rowData.userId,
      //         orgId: this.curNode.id,
      //         pageNo: 1,
      //         pageSize: 9999,
      //       },
      //     });
      //   })
      if (!this.isAuth("obd:getEquipmentInfo"))
        return this.$message.warning("没有权限!obd:getEquipmentInfo");
      this.$http({
        url: this.$http.adornUrl("obd/getEquipmentInfo"),
        method: "post",
        data: {
          userId: this.rowData.userId,
          orgId: this.curNode.id,
          pageNo: 1,
          pageSize: 9999,
        },
      })
        .then((res) => {
          let _checkAllObdList = this.checkAllObdList;
          if (_checkAllObdList == 0) {
            res.data.data.records.forEach((e) => (e._checked = false));
          } else {
            res.data.data.records.forEach((e) => {
              _checkAllObdList.find(
                (i) =>
                  e.carPlateNum == i.carPlateNum &&
                  e.carPlateColor == i.carPlateColor
              ) == undefined
                ? (e._checked = false)
                : (e._checked = true);
            });
          }
          this.allObdList = res.data.data.records;
          this.tableData = [...res.data.data.records];
        })
        .catch((err) => {
          this.allObdList = [];
        });
    },
    getEmuserEquipment() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: this.$http.adornUrl("obd/selectEmuserEquipment"),
          method: "get",
          params: this.$http.adornParams({
            userId: this.rowData.userId,
          }),
        }).then((res) => {
          this.checkAllObdList = res.data.data;
        });
      });
    },
    handleCheckAllObdList(val) {
      console.log(val);
      console.log(this.allObdList);
    },
    selectChange(arr) {
      this.curChecked = arr;
      arr.forEach((e) => {
        let cur = this.allObdList.find((item) => {
          return (
            item.carPlateNum == e.carPlateNum &&
            item.carPlateColor == e.carPlateColor
          );
        });
        if (cur) {
          cur._checked = true;
        }
      });
    },
    selectCancelRow(arr, row) {
      let _checkAllObdList = this.checkAllObdList;
      _checkAllObdList.forEach((e, i) => {
        if (
          e.carPlateNum == row.carPlateNum &&
          e.carPlateColor == row.carPlateColor
        ) {
          _checkAllObdList.splice(i, 1);
        }
        this.allObdList.find((item) => {
          return (
            item.carPlateNum == e.carPlateNum &&
            item.carPlateColor == e.carPlateColor
          );
        })._checked = false;
      });
      this.checkAllObdList = _checkAllObdList;
    },
    selectAllCancel() {
      let _allObdList = this.allObdList;
      let _checkAllObdList = this.checkAllObdList;
      _allObdList.forEach((e) => {
        _checkAllObdList.forEach((v, i) => {
          if (
            v.carPlateNum == e.carPlateNum &&
            v.carPlateColor == e.carPlateColor
          ) {
            _checkAllObdList.splice(i, 1);
            e._checked = false;
          }
        });
      });
      this.checkAllObdList = _checkAllObdList;
    },
    handleClose(item, index) {
      this.checkAllObdList.splice(index, 1);
      let _allObdList = this.allObdList;
      let curIndex = _allObdList.findIndex(
        (e) =>
          e.carPlateNum == item.carPlateNum &&
          e.carPlateColor == item.carPlateColor
      );
      if (curIndex == -1) return;
      console.log(curIndex);
      let copy_allObdItem = { ..._allObdList[curIndex] };
      copy_allObdItem._checked = false;
      _allObdList.splice(curIndex, 1, copy_allObdItem);
    },
    submit() {
      let url = `obd/updateEmuserEquipment?userId=${this.rowData.userId}`;
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: this.checkAllObdList.map((e) => {
          return {
            carPlateNum: e.carPlateNum,
            carPlateColor: e.carPlateColor,
          };
        }),
      })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.visible = false;
          this.$emit("getList");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@solidColor: #666;
.top {
  width: 100%;
  height: 400px;
  display: flex;
  &-tree,
  &-carList {
    border: solid 1px @solidColor;
    width: 50%;
    height: 100%;
    overflow: hidden;
    &-scroll {
      width: calc(100% + 17px);
      height: 100%;
      overflow-y: scroll;
      padding-left: 10px;
    }
  }
  &-tree {
    border-right: none;
  }
}
.bottom {
  width: 100%;
  height: 100px;
  border: solid 1px @solidColor;
  border-top: none;
  overflow: hidden;
  &-scroll {
    width: calc(100% + 17px);
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
  }
}
</style>
